<template>
  <div class="personnelManagement">
    <div class="listBox">
      <div class="search">
        <div class="queryParams">
          <el-input
            size="small"
            placeholder="输入手机号查询"
            v-model="search"
            clearable
          >
          </el-input>
          <el-button
            slot="append"
            size="small"
            type="primary"
            style="border-top-left-radius: 0; border-bottom-left-radius: 0"
            @click="handleSearch"
            >邀请</el-button
          >
        </div>
      </div>
      <div class="list" v-if="dataList.length">
        <el-table
          :data="dataList"
          :header-cell-style="{
            background: '#F5F5F5',
            fontWeight: 600,
            fontSize: '14px',
            color: '#000000d9',
          }"
        >
          <el-table-column label="序号" type="index" width="50">
          </el-table-column>
          <el-table-column prop="roleName" label="角色"> </el-table-column>
          <el-table-column prop="realName" label="姓名"> </el-table-column>
          <el-table-column prop="userName" label="登录账号"> </el-table-column>
          <el-table-column prop="authenticationStatus_cn" label="实名认证">
          </el-table-column>
          <el-table-column prop="joinTime" label="加入时间" width="180">
          </el-table-column>
          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <el-button
                type="text"
                :disabled="scope.row.roleId == 4"
                @click="handleEdit(scope.row.userId)"
                >设为管理员</el-button
              >
              <el-button
                type="text"
                :disabled="scope.row.roleId == 4"
                @click="handleDelete(scope.row)"
                >删除角色</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :page-sizes="[10, 50, 100, 200]"
            layout="total, prev, pager, next,sizes, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="list" v-else>
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryManuStaff,
  invitePersonnel,
  queryUserByPhone,
  deleteMember,
  editRole,
} from "@/api/personnelManagement";
export default {
  name: "Project",

  data() {
    return {
      search: "",
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataList: [],
    };
  },
  mounted() {
    this.$store.dispatch("getUserInfo");
    this.getlist();
  },
  methods: {
    handleSearch() {
      queryUserByPhone({ phone: this.search }).then(({ code, data }) => {
        if (code == 200) {
          this.$confirm(
            `<div> 您确定邀请“${
              this.search
            }”用户成为您的企业员工吗？</div><div> 用户姓名 : ${
              data.realName || "--"
            }</div>`,
            "邀请成员",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              dangerouslyUseHTMLString: true,
            }
          )
            .then(() => {
              invitePersonnel({ userName: data.userName }).then((res) => {
                if (res.code == 200) {
                  this.$message.success(res.msg);
                  this.getlist();
                } else {
                  this.$message.error(res.msg);
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消邀请",
              });
            });
        } else {
          this.$alert(
            "手机号 " + this.search + " 暂未注册，请注册后再进行邀请。",
            "邀请成员",
            {
              confirmButtonText: "知道了",
            }
          );
        }
      });
    },
    handleEdit(userId) {
      this.$confirm(
        "将该角色替换为管理员，当前的管理员变为普通成员角色, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          editRole({ userId }).then(({ code, msg }) => {
            if (code == 200) {
              this.$message.success(msg);
              this.$store.dispatch("getUserInfo");
              this.getlist();
            } else {
              this.$message.error(msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消替换",
          });
        });
    },
    handleDelete(row) {
      this.$confirm("您确认删除企业成员" + row.realName + "吗?", "删除成员", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMember({ userId: row.userId }).then(({ code, msg }) => {
            if (code == 200) {
              this.$message.success(msg);
              this.getlist();
            } else {
              this.$message.error(msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getlist() {
      queryManuStaff(this.queryParams).then((res) => {
        if (res.code == 200) {
          this.dataList = res.rows;
          let obj = {
            0: "待认证",
            3: "认证失败",
            1: "认证中",
            2: "已认证",
          };
          this.dataList.forEach((item) => {
            item.roleName = item.roles[0].roleName;
            item.roleId = item.roles[0].roleId;
            item.authenticationStatus_cn = obj[item.authenticationStatus];
            item.joinTime = item.joinTime || item.createTime;
          });
          this.total = res.total;
        }
      });
    },
    goToDetail(id) {
      this.$router.push({
        path: "/personnelManagement/announcementDetails",
        query: {
          auctionCode: id,
        },
      });
    },
    // 当前页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getlist();
    },
    // 每页几条
    handleSizeChange(val) {
      this.pageSize = val;
      this.getlist();
    },
  },
};
</script>

<style lang="less" scoped>
.personnelManagement {
  margin-left: 40px;
  .listBox {
    .search {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-end;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #000000d9;
      }
      .queryParams {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .list {
      width: 100%;
    }
  }
  /deep/.el-input--small .el-input__inner {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .pagination {
    width: 100%;
    // background-color: #ccc;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
}

</style>
<style lang="less">
.el-message-box__title {
  font-weight: 600;
}

.el-message-box {
  .el-button {
    width: 72px;//修改确认按钮的宽度
    height: 40px;//修改确认按钮的宽度
  }
  .el-message-box__btns{
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
</style>
